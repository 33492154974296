// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Page1 = (props: PageProps) => (
  
  <Layout backgroundColor="#FAFAFA">
    <SEO title="Sketch #1" />
    {/* <Link to="/">Back</Link>  
     */}

    <div className="flex justify-center ">
      <div className="w-full md:w-2/3">
        <iframe src="https://yanngraf.github.io/codesbars/001_codebars/" width="100%" height="800"></iframe>

        <p className="text-center text-sm mt-40 text-gray-400">This sketch has been tokenized and is available for sale. <a className="underline" href="https://app.rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:4125">More info</a>
        </p>

      </div>
    </div>

  </Layout>
)

export default Page1
